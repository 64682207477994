<template>
  <footer class="hero-footer" style="padding-top: 2rem">
    <div class="is-flex footer-wrapper">
      <div class="column footer-social is-one-third has-text-centered">
        <a
          href="https://www.facebook.com/liffhappens/"
          target="_blank"
          rel="noopener"
          style="margin-right: 2rem">
          <o-icon
            icon="facebook"
            size="medium"
            title="View liffhappens’s profile on Facebook">
          </o-icon>
        </a>
        <a
          href="https://twitter.com/liffhappens/"
          target="_blank"
          rel="noopener"
          style="margin-right: 2rem">
          <o-icon
            icon="twitter"
            size="medium"
            title="View liffhappens’s profile on Twitter">
          </o-icon>
        </a>
        <a
          href="https://www.instagram.com/liffhappens/"
          target="_blank"
          rel="noopener"
          style="margin-right: 2rem">
          <o-icon
            icon="instagram"
            size="medium"
            title="View liffhappens’s profile on Instagram">
          </o-icon>
        </a>
      </div>
      <div class="column footer-logo">
        <div class="content has-text-centered">
          <img
            width="135"
            height="56"
            src="https://storage.googleapis.com/liff-concept-183718.appspot.com/assets/liffapp-logo.png" />
        </div>
        <div class="content has-text-centered">
          <p class="liff-font">&copy; Liff Happens, Inc. 2025</p>
        </div>
      </div>
      <div class="column footer-policy">
        <div class="content has-text-centered">
          <a href="https://www.liffhappens.com/privacy-policy/">
            Privacy Policy
          </a>
          and
          <a href="https://www.liffhappens.com/terms-of-service/">
            Terms and Conditions
          </a>
        </div>
        <div class="content has-text-centered">
          <a href="https://www.liffhappens.com/information-requests/">
            Do Not Sell My Personal Information
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'LiffFooter',

  components: {},

  props: {},

  computed: {},

  methods: {},
}
</script>

<style scoped lang="scss">
.footer-wrapper {
  flex-wrap: wrap;
}

.footer-wrapper > .column {
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.footer-social {
  @media (max-width: 768px) {
    order: 2;
  }
}

.footer-logo {
  @media (max-width: 768px) {
    order: 1;
  }
}

.footer-policy {
  @media (max-width: 768px) {
    order: 3;
  }
}
</style>
