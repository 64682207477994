import { isProxy, toRaw } from 'vue'
import axios from 'axios'

axios.interceptors.response.use(
  (response) => {
    // Do something before request is sent
    return response
  },
  (error) => {
    if (error.response) {
      // request was made and status code is not 2xx
      let hasLoginLocalDev =
        window.location.pathname.startsWith('/a/') ||
        window.location.pathname.startsWith('/reunite/')
      let hasLoginDeployed =
        window.location.hostname.startsWith('accounts') ||
        window.location.hostname.startsWith('reunite')
      let isAlreadyLoginPage = window.location.hash.includes('login')
      let isUnauthorized = error.response.status == 401

      if (
        isUnauthorized &&
        !isAlreadyLoginPage &&
        (hasLoginLocalDev || hasLoginDeployed)
      ) {
        window.location.hash = '#/login'
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in
      // the browser
      // ... offline
    } else {
      // Something happened in setting up the request that triggered an Error
    }
    console.debug(error.config)

    throw error
  }
)
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json'

const api = {
  csrfToken: '',
  DEBUG: false,
  errors: [],

  onLoading(req) {
    this.safeLog(req)
  },

  onEndLoading(req, data) {
    this.safeLog(req.url, data)
  },

  init(csrfToken, debug = false) {
    const self = this
    self.csrfToken = csrfToken
    self.DEBUG = debug

    axios.defaults.headers.post['Content-Type'] = 'application/json'

    return this
  },

  safeLog() {
    if (arguments && this.DEBUG) {
      try {
        console.debug('API: ', arguments)
      } catch (err) {
        console.error(err)
      }
    }
  },

  _rootUrl(fxn) {
    return `/api/${fxn}/`
  },

  _doRequest(req) {
    // ref: https://axios-http.com/docs/req_config
    const self = this
    this.onLoading(req)
    return axios(req)
      .catch((error) => {
        /*
        if (error.status == 402) {
          // generate new appcheck token and resubmit
          return axios(req)
        }
        */
        try {
          if (error.response?.data?.error == 'csrf') {
            self.csrfToken = error.response.data.csrf_token
          }
          self.errors.push(error)
        } catch (err) {
          console.error(err)
        }
        throw error?.response || error
      })
      .finally((data) => self.onEndLoading(req, data))
  },

  _doGet(url, params, headers) {
    return this._doRequest({
      url: url,
      method: 'GET',
      params: params,
      headers: headers,
      responseType: 'json',
    })
  },

  _doPostRequest(url, data, headers) {
    return this._doRequest({
      url: url,
      method: 'POST',
      data: JSON.stringify(data),
      headers: headers,
      responseType: 'json',
    })
  },

  _doPutRequest(url, data, headers) {
    return this._doRequest({
      url: url,
      method: 'PUT',
      data: data,
      headers: headers,
      responseType: 'json',
    })
  },

  _doPatchRequest(url, data, headers) {
    return this._doRequest({
      url: url,
      method: 'PATCH',
      data: data,
      headers: headers,
      contentType: 'application/json; charset=utf-8',
      responseType: 'json',
    })
  },

  event(event, org, body) {
    console.debug(`API: event(${event}, ${org}, ${body})`)
    let form = {
      event: event,
      org: org,
      body: body || null,
    }
    return api
      ._doPostRequest(`/api/analytics`, form)
      .then((response) => response.data)
      .catch(() => {})
  },

  alerts: {
    create(form) {
      console.debug(`API: alert.create(`, form, `)`)
      return api._doPostRequest('/api/items/alerts', form)
    },

    get(token) {
      return api._doGet(`/api/alerts/${token}`)
    },

    patch(token, form) {
      return api._doPatchRequest(`/api/alerts/${token}`, form)
    },

    cancel(token) {
      return api._doPatchRequest(`/api/alerts/${token}`, {
        state: 'cancelled',
      })
    },
  },

  categories: {
    get() {
      return api._doGet('/api/organization/categories')
    },
  },

  claimManager: {
    appCheck: null, // function that returns appCheck Token
    authToken: null,

    async headers() {
      if (this.appCheck === null) {
        throw new Error('Invalid appCheck. Reload or try a new browser.')
      }
      if (this.authToken === null) {
        throw new Error('Please log in.')
      }
      return {
        Authorization: `Bearer ${this.authToken}`,
        'X-Firebase-AppCheck': await this.appCheck(),
      }
    },

    async auth(authToken, form) {
      /* Get and auth the subject claim with authToken
       *
       * form:
       *  email: email address that matches the claimant
       *
       * Returns
       *  entity
       */
      this.authToken = authToken
      let headers = {
        Authorization: `Bearer ${authToken}`,
        'X-Firebase-AppCheck': await this.appCheck(),
      }
      return api
        ._doPostRequest(`/api/claims/auth`, form, headers)
        .then((response) => {
          return response.data
        })
    },

    /////////////////////////////////////////////////
    // ClaimManager API Methods
    /////////////////////////////////////////////////

    async get(claimKey) {
      return api
        ._doGet(`/api/claims/${claimKey}`, form, await this.headers())
        .then((response) => response.data)
    },

    async intlRates(claimKey, form) {
      /* Get rates for an international shipment
       *
       * Arguments:
       *  form: schemas.shipping.EasyPostAddress
       */
      return api
        ._doGet(
          `/api/claims/${claimKey}/international`,
          form || {},
          await this.headers()
        )
        .then((response) => response.data)
    },

    async relinquish(claimKey, form) {
      return api
        ._doPostRequest(`/api/claims/relinquish`, form, await this.headers())
        .then((response) => response.data)
    },

    async submitInternational(claimKey, form) {
      /* Create international shipment with address; Await adding customs.
       * Arguments:
       *  form: schemas.shipping.AddressForm
       */
      return api
        ._doPutRequest(
          `/api/claims/${claimKey}/international`,
          form,
          await this.headers()
        )
        .then((response) => response.data)
    },

    async rates(claimKey, form) {
      return api
        ._doPostRequest(
          `/api/claims/${claimKey}/rates`,
          form,
          await this.headers()
        )
        .then((response) => response.data)
    },

    async submitPurchase(claimKey, form) {
      /*
        form: {
          addons: [
            // { key, quantity },
          ],
          addressId: null,
          billingAddress: {},
          claimKey: null,
          contribution: 0, // in cents
          insuredValue: 0, // in cents; min $50 (rate 1%)
          rateId: null,
          shipmentId: null,
          shippingAddress: {},
          stripeToken: null,
        }
       */
      return api
        ._doPutRequest(
          `/api/claims/${claimKey}/cart`,
          form,
          await this.headers()
        )
        .then((response) => response.data)
    },

    async submitSimpleShipment(claimKey, form) {
      return api
        ._doPutRequest(
          `/api/claims/${claimKey}/simple`,
          form,
          await this.headers()
        )
        .then((response) => response.data)
    },
  },

  consumer: {
    submitFeedback(form) {
      return api
        ._doPostRequest(`/api/consumer/feedback`, form)
        .then((response) => response.data)
    },
  },

  items: {
    get(itemKey) {
      return api._doGet(`/api/items/${itemKey}`)
    },

    search(q) {
      return api._doGet('/api/items', q)
    },

    createClaim(itemKey, form) {
      return api
        ._doPostRequest(`/api/items/${itemKey}/claim`, form)
        .then((response) => response.data)
    },
  },

  organization: {
    get() {
      return api._doGet('/api/organization')
    },

    getStats() {
      return api._doGet('/api/organization/stats')
    },
  },

  protect: {
    create(form) {
      return api._doPostRequest('/api/protect', form)
    },

    register(key, form) {
      return api._doPostRequest(`/api/protect/${key}`, form)
    },

    decode(url) {
      console.debug(`API: protect.decode(${url})`)
      let form = { url: url }
      return api._doGet(`/api/shortener`, form)
    },

    preregisterChild(form) {
      if (isProxy(form)) {
        form = toRaw(form)
      }
      console.debug(`API: protect.preregisterChild(${form})`)
      return api._doPostRequest(`/api/protect/child-prereg`, form)
    },

    registerChild(form) {
      if (isProxy(form)) {
        form = toRaw(form)
      }
      console.debug(`API: protect.registerChild(${form})`)
      return api._doPostRequest(`/api/protect/child`, form)
    },

    shareLocation(key, form) {
      console.debug(`API: protect.shareLocation(${key}, ${form})`)
      return api._doPostRequest(`/api/protect/${key}/location`, form)
    },

    get(key) {
      console.debug(`API: protect.get(${key})`)
      return api._doGet(`/api/protect/${key}`)
    },

    contactSubmit(key, form) {
      console.debug(`API: protect.contactSubmit(${key}, `, form, `)`)
      return api
        ._doPostRequest(`/api/protect/${key}/contact`, form)
        .then((response) => response.data)
    },

    addTag(token, form) {
      /* form: {
       *  key,
       *  name,
       * }
       */
      console.debug(`API: protect.addTag(${token}, `, form, `)`)
      return api._doPutRequest(`/api/user/${token}/`)
    },
  },

  utils: {
    getCSRF() {
      return axios.get('/api/csrf')
    },

    message: {
      admin(entityKey, data, message) {
        // sends a message to admin
        const form = {
          entity: entityKey,
          data: data || {},
          message: message || 'A message from User',
        }
        return api._doPostRequest(`/api/message/`, form)
      },
    },
  },

  account: {
    create(form) {
      console.debug(`API: account.create(`, form, `)`)
      return api._doPostRequest('/api/account/', form)
    },

    login(form, redirect) {
      // form: { email, appcheck, [reportKey] }
      let query = redirect ? `redirect=${redirect}` : ''
      return api
        ._doPostRequest(`/api/account/login?${query}`, form)
        .then((response) => response.data)
    },

    auth(form) {
      // form: { email, token }
      return api
        ._doPostRequest(`/api/account/auth`, form)
        .then((response) => response.data)
    },

    checkTag(token, url) {
      console.debug(`API: account.checkTag(${token}, ${url})`)
      let form = { url: url }
      return api._doGet(`/api/user/${token}/protect`, form)
      // should be inactive or already registered to your account
    },

    getProtectDetails(token) {
      return api
        ._doGet(`/api/account/${token}/protect/details`)
        .then((response) => response.data)
    },

    registerProtect(token, url) {
      return api
        ._doPutRequest(`/api/account/${token}/protect`, { url: url })
        .then((response) => response.data)
    },

    updateProtect(token, form) {
      return api
        ._doPostRequest(`/api/account/${token}/protect/${form.key}`, form)
        .then((response) => response.data)
    },

    getProfile(token) {
      return api
        ._doGet(`/api/account/${token}`)
        .then((response) => response.data)
    },

    updateFriends(token, form) {
      return api
        ._doPostRequest(`/api/account/${token}/friends`, form)
        .then((response) => response.data)
    },

    updateProfile(token, form) {
      return api
        ._doPostRequest(`/api/account/${token}/profile`, form)
        .then((response) => response.data)
    },
  },

  lossReports: {
    create(form) {
      return api
        ._doPostRequest('/api/loss/', form)
        .then((response) => response.data)
    },
  },

  reunite: {
    appCheck: null, // function that returns appCheck Token
    authToken: null,

    async headers() {
      if (this.appCheck === null) {
        throw new Error('Invalid appCheck. Reload or try a new browser.')
      }
      if (this.authToken === null) {
        throw new Error('Please log in.')
      }
      return {
        Authorization: `Bearer ${this.authToken}`,
        'X-Firebase-AppCheck': await this.appCheck(),
      }
    },

    handleUnauthorized(err, req) {
      console.debug(err)
      // status == 401 token refresh
      // status == 402 appCheck failed
      if (err.status == 401) {
        return this.refresh().then(req)
      } else {
        throw err
      }
    },

    async auth(form) {
      /*
       * form:
       *  token: Auth or Login token
       *  email: email address that matches the credentials of the token
       *
       * Returns
       *  authToken, entity
       */
      let headers = {
        Authorization: `Bearer ${form.token}`,
        'X-Firebase-AppCheck': await this.appCheck(),
      }
      return api
        ._doPostRequest(`/api/reunite/auth`, form, headers)
        .then((response) => {
          this.authToken = response.data.authToken
          return response.data
        })
        .catch((err) => {
          return this.handleUnauthorized(err, () =>
            api._doPostRequest(`/api/reunite/auth`, form)
          )
        })
    },

    async refresh() {
      return api._doPostRequest(`/api/reunite/refresh`, await this.headers())
    },

    async getUser() {
      return api
        ._doGet(`/api/reunite/user`, null, await this.headers())
        .then((response) => response.data)
    },

    async updateUser(form) {
      return api
        ._doPostRequest(`/api/reunite/user`, form, await this.headers())
        .then((response) => response.data)
    },

    async queryList() {
      return api
        ._doGet(`/api/reunite/imei`, null, await this.headers())
        .then((response) => response.data)
    },

    async queryCreate(form) {
      return api
        ._doPostRequest(`/api/reunite/imei`, form, await this.headers())
        .then((response) => response.data)
    },

    async login(form) {
      // form: { email }
      let headers = {
        'X-Firebase-AppCheck': await this.appCheck(),
      }
      return api
        ._doPostRequest(`/api/reunite/login`, form, headers)
        .then((response) => response.data)
    },
  },
}

export default api
